import { Proposal } from './proposal';
import { Product } from './product';

export class Quotation{
    quotationDate: string
    netPremiumAfterDiscount: number
    stampDuty: number
    supervisionFees: number
    dimensionalTax: number
    issuanceFee: number
    grossRate: number
    finalPremium: number
    product: Product
    id: number
    // proposal: Proposal

    constructor() {
        this.dimensionalTax = 5;
        this.finalPremium = 1925000;
        this.grossRate = 4176;
        this.issuanceFee = 13307;
        this.netPremiumAfterDiscount = 1809842;
        this.quotationDate = '2018-08-30';
        this.stampDuty = 90492 ;
        this.supervisionFees = 10859;
        this.product = new Product
        this.id = 0
        // this.proposal = new Proposal();
        }
}