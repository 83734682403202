import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Validator } from '../shared/validator';
import { ApiService } from 'src/services/api.service';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.css']
})
export class ResetPasswordComponent implements OnInit {

  fg: FormGroup

  submitted: Boolean = false 

  constructor(private formBuilder: FormBuilder,private apiService: ApiService, private toast: ToastrService, private router: Router) { }

  ngOnInit() {
    this.fg =  this.formBuilder.group({
      current_password : ['',[Validators.required]],
      password: ['',[Validators.required]],
      confirmPassword: ['',[Validators.required]],
    },
    {
      validator: [Validator.MatchPassword,Validator.isComplexPassword],
    })
  }

  onSubmit() {
    this.submitted = true
    if (this.fg.invalid) {
      return false
    }else {
      let data = this.fg.value
      data['password_confirmation'] = data['confirmPassword']
      delete data['confirmPassword']
      this.apiService.postRequest('reset_password', data).subscribe(
        data => {
            this.toast.success(data['data']);
            this.router.navigate(['/']);
        },
        error => {
          let errors = error.error.errors 
          if (typeof errors == 'object') {
            Object.keys(errors).forEach(key => {
                this.toast.error(errors[key])
            })
          }else if (typeof errors == 'string') {
            this.toast.error(errors)
          }
        }
      );    
    }
  }

}
