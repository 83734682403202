import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from "@angular/common/http";
import { paths } from '../app/shared/paths';
import { RequestOptions } from '@angular/http';

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  constructor(private httpClient:HttpClient,
              ) { }
  url;
  header;
  generateFullUrl (key: string) {
    return paths.apiBaseUrl+paths[key];
  }

  createHeader() {
    let headers = new Headers();
    let user = localStorage.getItem('user');
    headers['Authorization'] = 'Bearer ' + user['token'];
    return headers; 
  }

  postRequest (key:string, body){
    this.url = this.generateFullUrl(key);
    let header = this.createHeader();
    return this.httpClient.post(this.url,body, { headers: this.header })
  }

  getRequest (key:string, param){
    this.url = this.generateFullUrl(key);
    this.header = this.createHeader();
  
    return this.httpClient.get(this.url, { headers: this.header ,params:param})
  }
}
