import { Component, OnInit, TemplateRef, Output, EventEmitter } from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import { HttpClient, HttpParams } from "@angular/common/http";
import { paths } from '../shared/paths';
import { Proposal } from '../_models/proposal';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { Subscription } from 'rxjs';
import { Quotation } from '../_models/quotation';
import { ApiService } from 'src/services/api.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-report',
  templateUrl: './report.component.html',
  styleUrls: [
    './report.component.css'
  ]
})
export class ReportComponent implements OnInit {
  closeResult: string;
  modalRef: BsModalRef;
  subscriptions: Subscription[] = [];
  messages: string[] = [];
  product: string

  constructor(
    private activeRoute: ActivatedRoute,
    private router: Router,
    private httpClient: HttpClient,
    private modalService: BsModalService,
    private apiService:ApiService,
    private toastr: ToastrService,
    ) {}
  proposal = new Proposal; 
  quotation = new Quotation;
  
  ngOnInit() {
    window.scrollTo(0, 0);
    let proposalId = this.activeRoute.snapshot.params.id;
    this.httpClient.get(paths.apiBaseUrl+'/api/proposals/'+proposalId).subscribe(
      data => {
        this.proposal = data['data']
      },
      error => {
        this.toastr.error("Proposal not found")
        this.router.navigate(['/']);
      }
    )
  }

  ExpirationDate(date,days) {
    var result = new Date(date);
    result.setDate(result.getDate() + days);
    return result;
  }

  openModal(template: TemplateRef<any>,quotation) {
    this.modalRef = this.modalService.show(template);
    this.quotation = quotation
  }

  closeModal(){
    this.modalRef.hide();
  }

  isActive(quotation){
    return (this.proposal.submitted_quotation == 0) || this.proposal.submitted_quotation == quotation.id
  }

  // unSubmit(){
  //   this.apiService.postRequest('unsubmit_quotation',{proposal_id: this.proposal['id']}).subscribe(
  //     data => {
  //       this.toastr.success(data['message'])
  //       this.proposal.submitted_quotation = 0
  //     },
  //     error => {
  //       this.toastr.error("Cannot un-submit the quotation")
  //     }
  //   )
  // }
}
