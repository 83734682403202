import {Quotation} from './quotation';

export class Proposal {
  product_id: number;
  gender: string;
  birth_date: string;
  year_id: number;
  model_id: number;
  manufacturer_id: number;
  market_value: number;
  remove_agency_deductible: number;
  comments: string;
  cached: object;
  quotations: object;
  benefits: object;
  name: string;
  identifier: string;
  mobile: string;
  submitted_quotation: number;
  manufacturer: string;
  model: string;
  year: string;
  licenced: number;
  remove_network_deductible: number;
  email: string;
  coinsurance_percentage: number;
  occupation_class: string;
  governorate: string;

  constructor() {
    this.product_id = 1,
      this.gender = 'male',
      this.birth_date = '1999-02-02',
      this.comments = 'test comments',
      this.model_id = 1,
      this.market_value = 60000,
      this.manufacturer_id = 1,
      this.remove_agency_deductible = 0,
      this.year_id = 1,
      this.quotations = [],
      this.benefits = {
        en: null,
        ar: null
      },
      this.name = '',
      this.identifier = '';
    this.mobile = null;
    this.submitted_quotation = 0;
    this.licenced = 1;
  }
}
