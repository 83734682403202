import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { UserService } from '../_services/user.service';
import { Validator } from '../shared/validator';

@Component({
  selector: 'app-forget-password-reset',
  templateUrl: './forget-password-reset.component.html',
  styleUrls: ['./forget-password-reset.component.css']
})
export class ForgetPasswordResetComponent implements OnInit {
  email: string;
  token: string;
  submitted = false;
  invitationForm: FormGroup;
  constructor(private route: ActivatedRoute,
              private formBuilder: FormBuilder,
              private navigateRouter: Router,
              private toast: ToastrService,
              private userService: UserService
  ) {}

  ngOnInit() {
    this.route.queryParams.subscribe(params => {
      this.email = params['email'];
      this.token = params['token'];
    });
    this.invitationForm = this.formBuilder.group({
      password: ['', [Validators.required, Validators.minLength(8)]],
      confirmPassword: ['', Validators.required],
    },
    {
      validator: [Validator.MatchPassword,Validator.isComplexPassword]
    });
  }

  get form() { return this.invitationForm.controls; }

  onSubmit() {
    this.submitted = true;
    if (this.invitationForm.invalid) {
      return;
    }
    const data = Object.assign(this.invitationForm.getRawValue(), this.route.snapshot.queryParams);
    data['password_confirmation'] = data['confirmPassword']
    delete data['confirmPassword']
    this.userService.forgetPasswordReset(data)
      .subscribe(result => {
        this.toast.success('Your password has been reset!');
        this.navigateRouter.navigate(['/']);
    }, error1 => {
          this.toast.error('Token invalid or has been expired');
      });

  }
}
