import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Proposal } from 'src/app/_models/proposal';
import { Quotation } from 'src/app/_models/quotation';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ApiService } from 'src/services/api.service';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';

const objectToFormData1 = require('object-to-formdata');
const objectToFormData = objectToFormData1['default']

@Component({
  selector: 'app-submit-modal',
  templateUrl: './submit-modal.component.html',
  styleUrls: ['./submit-modal.component.css']
})
export class SubmitModalComponent implements OnInit {

  @Input() proposal : Proposal

  @Input() quotation : Quotation

  @Output() closeModal = new EventEmitter <any> ()

  quotationForm:FormGroup

  submitted: boolean

  loading: boolean

  constructor(
    private fb:FormBuilder,
    private apiService:ApiService,
    private toastr: ToastrService,
    private router: Router
    ) { }

  ngOnInit() {
    this.createFormGroup()
    this.submitted = false
    this.loading = false
  }
  createFormGroup(){
    this.quotationForm = this.fb.group({
      name:[this.proposal['name'],Validators.compose([Validators.required,Validators.pattern('^[A-Za-z ]*$')])],
      identifier:[
        this.proposal['identifier'],
        Validators.compose([
          Validators.required,
          Validators.pattern('^(2|3)[0-9][0-9][0-1][0-9][0-3][0-9](01|02|03|04|11|12|13|14|15|16|17|18|19|21|22|23|24|25|26|27|28|29|31|32|33|34|35|88)[0-9]{5}$')
        ])
      ],
      mobile:[
        this.proposal['mobile'],
        Validators.compose([
          Validators.required,
          Validators.pattern('(01)[0-9]{9}')
        ])
      ],
      attachments: this.fb.group({
        national_id:[
          null,
          Validators.compose([
            Validators.required,
            Validators.maxLength(2)
          ])
        ],
        driver_licence:[
          null,
          Validators.compose([
            Validators.required,
            Validators.maxLength(2)
          ])
        ],
        inspection:[
          null,
          Validators.compose([
            Validators.maxLength(10),
            ...[ this.proposal.licenced ? Validators.required : undefined ]
          ])
        ],
        car_licence:[
          null,
          Validators.compose([
            Validators.maxLength(2)
          ])
        ],
      })
    })
  }
  onSubmit(){
    if (!this.loading) {
      if(this.quotationForm.invalid){
        Object.keys(this.quotationForm.controls).forEach(key=>{
          this.quotationForm.get(key).markAsDirty()
        })
        this.submitted = true
      }else{
        this.submitted = false
        var obj = this.quotationForm.getRawValue()
        obj.quotation_id = this.quotation['id']
        this.loading = true
        let formData = objectToFormData(
          obj
        );
  
        this.apiService.postRequest('select_quotation', formData).subscribe(
          data => {
            this.loading = false
              this.toastr.success(data['message']);
              this.closeModal.emit();
              this.quotationForm.reset();
              this.router.navigate(['/']);
          },
          error => {
            this.loading = false;
            if (error.status != 429)
              this.toastr.error('Sorry something went wrong');
          }
      );
      }
    }

  }

  attachFiles(fileInput,key){
    this.quotationForm.get('attachments').get(key).setValue(fileInput.target.files)
  }
  close(){
    this.quotationForm.reset();
    this.closeModal.emit();
  }
}
