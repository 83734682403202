import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { UserService} from './user.service';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  constructor(private authenticationService: UserService,private toast: ToastrService, private router: Router) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(catchError(err => {
      if (err.status === 423) {
        // auto logout if 401 response returned from api
        this.authenticationService.logout();
        location.reload(true);
      }else if (err.status === 401) {
        this.toast.error('Session expired, Please login again')
        this.router.navigate(['login']);
      }
      else if (err.status === 402) {
        this.toast.error(err.error.error)
        this.router.navigate(['reset-password']);
      }
      else if (err.status === 429) {
        if (typeof err.error == 'object'){
          Object.keys(err.error).forEach(message => this.toast.error(err.error[message]))
        }
      }
      // const error = err.error;
      return throwError(err);
    }));
  }
}
