import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { tap, catchError, map } from 'rxjs/operators';
import {User} from '../_models/user';
import {paths} from '../shared/paths';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

@Injectable({
  providedIn: 'root'
})
export class UserService {

  constructor(private http: HttpClient) { }
  private loginUrl = `${paths.apiBaseUrl}/api/${paths.login}`;
  private registerUrl = `${paths.apiBaseUrl}/api/${paths.register}`;
  private logoutUrl = `${paths.apiBaseUrl}/${paths.logout}`;
  private resetPasswordUrl = `${paths.apiBaseUrl}${paths.forget_password_reset}`;
  private forgetPasswordUrl = `${paths.apiBaseUrl}${paths.forget_password}`;
  public loggedIn = true;
  login(email: string, password: string): Observable<User> {
    return this.http.post <any>(this.loginUrl, { email: email, password: password }, httpOptions)
      .pipe(map( response => {
          const  user = this.getUserFromToken(response.data.token);
          localStorage.setItem('user', JSON.stringify(user));
          this.loggedIn = true;
          return user;
      } ));
  }

  logout() {
    // remove user from local storage to log user out
    this.loggedIn = false;
    return this.http.post(this.logoutUrl, {} , httpOptions)
  }

  getUserFromToken(token) {
    const encoded_data = token.split(".");
    let data = new User;
    if (encoded_data.length === 3){
      data = JSON.parse(atob(encoded_data[1]));
    }
    data.token = token;
    return data;
  }

  register(data: object) {
    return this.http.post(this.registerUrl, data , httpOptions)
      .pipe(map( response => {
        const  user = this.getUserFromToken(response['data'].token);
        localStorage.setItem('user', JSON.stringify(user));
        return user;
      } ));
  }

  forgetPasswordReset(data: object) {
    return this.http.post(this.resetPasswordUrl, data , httpOptions).pipe(map( response => {
      const  user = this.getUserFromToken(response['data'].token);
      localStorage.setItem('user', JSON.stringify(user));
      return user;
    } ));
  }

  forgetPassword(data: object) {
    return this.http.post(this.forgetPasswordUrl, data , httpOptions);
  }

  get user()
  {
    if (localStorage.getItem('user')){
      return JSON.parse(localStorage.getItem('user'));
    } else {
      return new User();
    }

  }
}
