import { Injectable } from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class ValidInvitationService implements CanActivate{

  constructor(private router: Router) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot){
    if (!route.queryParamMap['params'].email || !route.queryParamMap['params'].token) {
      this.router.navigate(['/login']);
    }
    return true;
  }
}
