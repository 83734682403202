import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { User } from '../_models/user';
import { UserService } from '../_services/user.service';
import { ToastrService } from 'ngx-toastr';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-forget-password',
  templateUrl: './forget-password.component.html',
  styleUrls: ['./forget-password.component.css']
})
export class ForgetPasswordComponent implements OnInit {

  loginForm: FormGroup;
  user: User;
  returnUrl: string;

  constructor(private formBuilder: FormBuilder,
              private userService: UserService,
              private toastr: ToastrService,
              private route: ActivatedRoute,
              private router: Router
  ) { }

  ngOnInit() {
    this.loginForm = this.formBuilder.group({
      email: ['', Validators.compose([Validators.required, Validators.email])]
    });

    this.userService.logout();
  }

  onSubmit() {
    if (this.loginForm.valid) {
      this.userService.forgetPassword({
        email: this.loginForm.controls.email.value
      }).subscribe(
          response => {
                        this.router.navigate(['/']);
                        this.toastr.success(response['data']);
                       },
          err => {
            this.toastr.error(err.error.errors);

          });
    } else {
      Object.keys(this.loginForm.controls).forEach(key => {
        this.loginForm.get(key).markAsDirty();
      });
    }
  }
}
