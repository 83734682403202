import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { paths } from '../shared/paths';
import { IMyDpOptions, IMyDateModel } from 'mydatepicker';
import { ApiService } from 'src/services/api.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-reports',
  templateUrl: './reports.component.html',
  styleUrls: ['./reports.component.css']
})
export class ReportsComponent implements OnInit {


  currentDate = new Date();

  maxDate = {
    year: this.currentDate.getFullYear(), month: this.currentDate.getMonth() +1, day: this.currentDate.getDate() +1
  };

  public myDatePickerOptions: IMyDpOptions = {
    dateFormat: 'yyyy-mm-dd',
    disableSince: this.maxDate
};


  constructor(private activeRoute: ActivatedRoute,
    private router: Router,
    private apiService:ApiService,
    private toastr: ToastrService,
  private httpClient: HttpClient) {}

  reports = []
  meta = {
    total: 0,
    per_page: 0
  }
  links = null

  fakeArray = new Array(12);

  ngOnInit() {
    this.getPage()
  }

  pageLink(page) {
    return `${paths.apiBaseUrl}/api/proposals?page=${page}`
  }

  Range(num)
  {
    return new Array(num);
  }

  getPage(pageNumber = 1,queryString = undefined)
  {
    window.scrollTo(0, 0);
    this.httpClient.get(`${paths.apiBaseUrl}/api/proposals?page=${pageNumber}${queryString && `&${queryString}` || ''}`).subscribe(
      data => {
        this.reports = data['data']
        this.meta = data['meta']
        this.links = data['links']
      }
    )
  }

  gotToReport(id)
  {
    this.router.navigate([`/report/${id}`]);
  }

  onDateChanged(event: IMyDateModel) {
    this.getPage(1,`date=${event.formatted}`)
  }

  // unSubmit(report){
  //   this.apiService.postRequest('unsubmit_quotation',{proposal_id: report.id}).subscribe(
  //     data => {
  //       this.toastr.success(data['message'])
  //       report.submitted_quotation = 0
  //     },
  //     error => {
  //       this.toastr.error("Cannot un-submit the quotation")
  //     }
  //   )
  // }
}
