import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Proposal } from 'src/app/_models/proposal';
import { Quotation } from 'src/app/_models/quotation';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ApiService } from 'src/services/api.service';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';

@Component({
  selector: 'app-share-modal',
  templateUrl: './share-modal.component.html',
  styleUrls: ['./share-modal.component.css']
})
export class ShareModalComponent implements OnInit {

  @Input() proposal : Proposal

  @Input() quotation : Quotation

  @Output() closeModal = new EventEmitter <any> ()

  quotationForm:FormGroup

  submitted: boolean

  loading: boolean
  
  constructor(
    private fb:FormBuilder,
    private apiService:ApiService,
    private toastr: ToastrService,
    private router: Router
    ) { }

  ngOnInit() {
    this.createFormGroup()
    this.submitted = false
    this.loading = false
  }
  createFormGroup(){
    this.quotationForm = this.fb.group({
      email:['',Validators.compose([Validators.required,Validators.email])],
    })
  }
  onSubmit(){
    if(this.quotationForm.invalid && !this.loading){
      Object.keys(this.quotationForm.controls).forEach(key=>{
        this.quotationForm.get(key).markAsDirty()
      })
      this.submitted = true
    }else{
      this.submitted = false
      var obj = this.quotationForm.value
      obj.quotation_id =this.quotation['id']
      this.loading = true
      this.apiService.postRequest('share_quotation', obj).subscribe(
        data => {
          this.loading = false
            this.toastr.success(data['message']);
            this.closeModal.emit();
            this.quotationForm.reset();
        },
        error => {
          this.loading = false
          this.toastr.error("Sorry somthing went wrong.")
        }
    );
    }
  }
  close(){
    this.quotationForm.reset();
    this.closeModal.emit();
  }
}
