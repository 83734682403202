import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import {ProposalComponent} from './proposal/proposal.component';
import {LoginComponent} from './login/login.component';
import { ReportComponent } from './report/report.component';
import { InviteComponent } from './invite/invite.component';
import {GuardService} from './_services/guard.service';
import {ValidInvitationService} from './_services/valid-invitation.service';
import { ReportsComponent } from './reports/reports.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { ForgetPasswordResetComponent } from './forget-password-reset/forget-password-reset.component';
import { ForgetPasswordComponent } from './forget-password/forget-password.component';

const routes: Routes = [
  {
    path : '',
    component : ProposalComponent,
    canActivate: [GuardService]
  },
  {
    path : 'login',
    component : LoginComponent
  },
  {
    path: 'reports',
    component: ReportsComponent,
    canActivate: [GuardService]
  },
  { path: 'report/:id',
    component: ReportComponent,
    canActivate: [GuardService]
  },
  {
    path: 'invite',
    component: InviteComponent,
    canActivate: [ValidInvitationService]
  },
  {
    path: 'reset-password',
    component: ResetPasswordComponent,
    canActivate: [GuardService]
  },
  {
    path: 'forget-password/reset',
    component: ForgetPasswordResetComponent
  },
  {
    path: 'forget-password',
    component: ForgetPasswordComponent
  }
];

@NgModule({
  exports: [RouterModule],
  imports: [ RouterModule.forRoot(routes, {useHash: true}) ]
})

export class AppRoutingModule { 

}
