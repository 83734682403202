import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Validator } from '../shared/validator';
import { ToastrService } from 'ngx-toastr';
import {UserService} from '../_services/user.service';

@Component({
  selector: 'app-invite',
  templateUrl: './invite.component.html',
  styleUrls: ['./invite.component.css']
})
export class InviteComponent implements OnInit {
  email: string;
  token: string;
  submitted = false;
  invitationForm: FormGroup;
  constructor(private route: ActivatedRoute,
              private formBuilder: FormBuilder,
              private navigateRouter: Router,
              private toast: ToastrService,
              private userService: UserService
  ) {}

  ngOnInit() {
    this.route.queryParams.subscribe(params => {
      this.email = params['email'];
      this.token = params['token'];
    });
    this.invitationForm = this.formBuilder.group({
      password: ['', [Validators.required, Validators.minLength(8)]],
      confirmPassword: ['', Validators.required],
    },
    {
      validator: [Validator.MatchPassword,Validator.isComplexPassword]
    });
  }

  get form() { return this.invitationForm.controls; }

  onSubmit() {
    this.submitted = true;
    if (this.invitationForm.invalid) {
      return;
    }
    const data = Object.assign(this.invitationForm.getRawValue(), this.route.snapshot.queryParams);
    data['password_confirmation'] = data['confirmPassword']
    delete data['confirmPassword']
    this.userService.register(data)
      .subscribe(result => {
        this.toast.success('logged in successfully');
        this.navigateRouter.navigate(['/']);
    }, error1 => {
        const error = error1.error
        if (typeof error == 'object' && error.errors) {
          Object.keys(error.errors).forEach(element => {
            this.toast.error(error.errors[element][0]);
          })
        }else
          this.toast.error('Token invalid or has been expired');
      });

  }

}
