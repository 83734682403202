import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule} from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';
import { AppRoutingModule} from './app-routing.module';
import { AppComponent } from './app.component';
import { ProposalComponent } from './proposal/proposal.component';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { LoginComponent } from './login/login.component';
import { APP_BASE_HREF } from '@angular/common';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { ReportComponent } from './report/report.component';
import { InviteComponent } from './invite/invite.component';
import { ToastrModule} from 'ngx-toastr';
import { MyDatePickerModule } from 'mydatepicker';
import { JwtInterceptor } from './_services/jwt.interceptor.service';
import { ErrorInterceptor } from './_services/error.interceptor.service';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { ReportsComponent } from './reports/reports.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ModalModule } from 'ngx-bootstrap/modal';
import { SubmitModalComponent } from './report/submit-modal/submit-modal.component';
import { ShareModalComponent } from './report/share-modal/share-modal.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { ForgetPasswordResetComponent } from './forget-password-reset/forget-password-reset.component';
import { ForgetPasswordComponent } from './forget-password/forget-password.component';


@NgModule({
  declarations: [
    AppComponent,
    ProposalComponent,
    LoginComponent,
    HeaderComponent,
    FooterComponent,
    ReportComponent,
    InviteComponent,
    ReportsComponent,
    SubmitModalComponent,
    ShareModalComponent,
    ResetPasswordComponent,
    ForgetPasswordResetComponent,
    ForgetPasswordComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    ReactiveFormsModule,
    FormsModule,
    HttpClientModule,
    BrowserAnimationsModule,
    ToastrModule.forRoot(),
    MyDatePickerModule,
    NgbModule.forRoot(),
    ModalModule.forRoot()
  ],
  providers: [
    {provide: APP_BASE_HREF, useValue: '/'},
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    ],
  bootstrap: [AppComponent]

})
export class AppModule { }
