import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {HttpClient, HttpParams} from '@angular/common/http';
import {ApiService} from '../../services/api.service';
import {Quotation} from '../_models/quotation';
import {Router} from '@angular/router';
import {ToastrService} from 'ngx-toastr';
import {IMyDpOptions} from 'mydatepicker';
import {Validator} from '../shared/validator';
import {Product} from '../_models/product';

@Component({
  selector: 'app-proposal',
  templateUrl: './proposal.component.html',
})

export class ProposalComponent implements OnInit {
  title = 'axa-Form';

  proposalForm: FormGroup;
  submitted = false;
  data = new Quotation;
  manufacturers = [];
  models = [];
  years = [];
  products = [];
  coinsurancePercentages = [];
  occupationClasses = [];
  governorates = [];
  proposalId: number;
  estimatedMarketValue;
  loading = false;
  exceedMarketValue: boolean;
  minMarketValue: boolean;

  currentDate = new Date();
  maxDate = {
    year: this.currentDate.getFullYear() - 21, month: this.currentDate.getMonth() + 1, day: this.currentDate.getDate()
  };
  public myDatePickerOptions: IMyDpOptions = {
    dateFormat: 'yyyy-mm-dd',
    disableSince: this.maxDate,
    showTodayBtn: false
  };
  defaultMonth = this.maxDate.year + '-' + this.maxDate.month;

  constructor(private formBuilder: FormBuilder,
              private httpClient: HttpClient,
              private router: Router,
              private apiService: ApiService,
              private toast: ToastrService) {
  }

  ngOnInit() {
    window.scrollTo(0, 0);
    this.proposalForm = this.formBuilder.group({
        market_value: ['', [Validators.required, Validators.pattern('^[0-9]+$'), Validator.ValidateMarketValue]],
        gender: ['', Validators.required],
        manufacturer_id: ['', Validators.required],
        model_id: ['', Validators.required],
        year_id: ['', Validators.required],
        coinsurance_percentage: ['', Validators.required],
        occupation_class_id: ['', Validators.required],
        governorate_id: ['', Validators.required],
        licenced: [1, Validators.required],
        comments: [''],
        birth_date_obj: ['', Validators.required],
        name: ['', Validators.compose([Validators.pattern('^[A-Za-z ]*$')])],
        identifier: ['', Validators.compose([Validators.pattern('^(2|3)[0-9][0-9][0-1][0-9][0-3][0-9](01|02|03|04|11|12|13|14|15|16|17|18|19|21|22|23|24|25|26|27|28|29|31|32|33|34|35|88)[0-9]{5}$')])],
        mobile: ['', Validators.compose([Validators.pattern('(01)[0-9]{9}')])],
        email: ['', Validators.compose([Validators.email])],
      },
      {
        validator: Validator.ValidateBirhtDate
      }
    );
    // this.getProducts();

    this.apiService.getRequest('manufacturers', []).subscribe(
      data => {
        this.manufacturers = data['data'];
      }
    );

    this.apiService.getRequest('coinsurancePercentages', []).subscribe(
      data => {
        this.coinsurancePercentages = data['data'];
      }
    );

    this.apiService.getRequest('occupationClasses', []).subscribe(
      data => {
        this.occupationClasses = data['data'];
      }
    );

    this.apiService.getRequest('governorates', []).subscribe(
      data => {
        this.governorates = data['data'];
      }
    );
  }

  get form() {
    return this.proposalForm.controls;
  }

  onSubmit() {
    this.submitted = true;

    if (this.proposalForm.invalid) {
      return;
    }
    // let dateOfBirth = this.proposalForm.controls.birth_date.value.formatted;
    // this.proposalForm.controls.birth_date.setValue(dateOfBirth);

    this.proposalForm.value['birth_date'] = this.proposalForm.get('birth_date_obj').value['formatted'];
    this.apiService.postRequest('proposals', this.proposalForm.value).subscribe(
      data => {
        this.proposalId = data['data'].id;
        this.toast.success('Proposal Created Successfully');
        this.router.navigate(['report', this.proposalId]);
        this.proposalForm.reset();
      }
    );

  }

  getModels() {
    this.proposalForm.controls.model_id.reset;
    this.proposalForm.controls.year_id.reset;
    let manufacturerId = this.proposalForm.controls.manufacturer_id.value;
    this.models = this.manufacturers.filter(manufacturer => manufacturer.id == manufacturerId)[0].models;
    this.years = [];
  }

  getYears() {
    let modelId = this.proposalForm.controls.model_id.value;
    this.years = this.models.filter(model => model.id == modelId)[0].years;
    this.proposalForm.controls.year_id.setValue('');
  }

  getProducts() {
    this.apiService.getRequest('products', {}).subscribe(
      data => {
        data['data'].products.forEach((item) => {
          const product = new Product();
          product.id = item.id;
          product.name = item.name;

          this.products.push(product);
        });

        this.proposalForm.controls.product_id.setValue(this.products[0].id);
      }
    );
  }
}
