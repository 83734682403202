import { AbstractControl, FormControl } from '@angular/forms';

export class Validator {
    static MatchPassword(AC: AbstractControl) {
        let password = AC.get('password').value;
        let confirmPassword = AC.get('confirmPassword').value;
        if(password != confirmPassword) {
             AC.get('confirmPassword').setErrors( {MatchPassword: true} )
         } else {
            return null
        }
    }

    static ValidateBirhtDate(AC: AbstractControl) {
        if(AC.get('birth_date_obj').value){
            let birthDate = new Date(AC.get('birth_date_obj').value.formatted);
            let currentDate = new Date();
            let latedDate = new Date(currentDate.getFullYear() - 21, currentDate.getMonth(), currentDate.getDate());
            if(birthDate > latedDate) {
                 AC.get('birth_date_obj').setErrors( {ValidateBirhtDate: true} )
             }
        }
            return null
    }

    static ValidateMarketValue(AC: AbstractControl) {
        if(AC.value){
            let marketValue = AC.value;
            
            if(marketValue < 71000) {
                return{ValidateMarketValue: true} 
             }
        }
            return null
    }

    static isComplexPassword(AC: AbstractControl) {
        let password = AC.get('password').value;
        if (!/^\S*(?=\S{8,})(?=\S*[a-z])(?=\S*[A-Z])(?=\S*[\d])(?=\S*[\W])/.test(password)) {
            AC.get('password').setErrors( {isComplexPassword: true} )
        }
        return null
    }

}
